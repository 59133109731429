import React, { useState, useEffect } from 'react';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import '../mens.collection.css';
import { Link } from 'react-router-dom';
import Header from '../common/head';
import Footer from '../common/footer';

const Womenssun = () => {
  const [products, setProducts] = useState([]);
  const [hoveredImage, setHoveredImage] = useState({});
  const models = {
    '2170': ['C54', 'C56', 'C106'],
    '2002': ['C1', 'C5'],
    '6337': ['C1', 'C2', 'C35', 'C36'],
    '6351': ['C1', 'C2', 'C8'],
    '7005': ['C1', 'C10', 'C15'],
    '7436': ['C3', 'C4', 'C5'],
    '7479': ['C1', 'C2', 'C4', 'C9'],
    '8816': ['C1', 'C4', 'C5'],
    
  };
  const genderId = {
    '2170': 'womens',
    '2002': 'womens',
    '6337': 'womens',
    '6351': 'womens',
    '7005': 'womens',
    '7436': 'womens',
    '7479': 'womens',
    '8816': 'womens',
    
  };

  
    // eslint-disable-next-line no-unused-vars
    const [gdId, setGdid] = useState(genderId);
    


    

    useEffect(() => {
      const updatePdcClass = () => {
        const pdcElements = document.querySelectorAll('#pdc');
        if (window.innerWidth < 575) {
          pdcElements.forEach((element) => {
            element.classList.add('col-6');
            element.classList.remove('col-4');
          });
        } else {
          pdcElements.forEach((element) => {
            element.classList.remove('col-6');
            element.classList.add('col-4');
          });
        }
      };
  
      const handleDesk1Click = () => {
        document.querySelectorAll('#pdc').forEach((element) => {
          element.classList.add('col-4');
          element.classList.remove('col-6');
        });
      };
  
      const handleDesk2Click = () => {
        document.querySelectorAll('#pdc' ).forEach((element) => {
          element.classList.remove('col-4');
          element.classList.add('col-6');
        });
      };
      const handleDesk5Click = () => {
        document.querySelectorAll('#tpimg' ).forEach((element) => {
          element.style.height = '310.66px';
        });
      };
      const handleDesk6Click = () => {
        document.querySelectorAll('#tpimg').forEach((element) => {
          element.style.height = '';
        });
      };
  
      const handleDesk3Click = () => {
        document.querySelectorAll('#pdc' ).forEach((element) => {
          element.classList.remove('col');
          element.classList.add('col-6');
          
        });
      };
  
      const handleDesk4Click = () => {
        document.querySelectorAll('#pdc').forEach((element) => {
          element.classList.remove('col-6');
          element.classList.add('col');
          
        });
      };
  
      const desk1Button = document.getElementById('desk1');
      const desk2Button = document.getElementById('desk2');
      const desk3Button = document.getElementById('desk3');
      const desk4Button = document.getElementById('desk4');
  
      if (desk1Button) desk1Button.addEventListener('click', handleDesk1Click);
      if (desk2Button) desk2Button.addEventListener('click', handleDesk2Click);
      if (desk2Button) desk2Button.addEventListener('click', handleDesk5Click);
      if (desk1Button) desk1Button.addEventListener('click', handleDesk6Click);
      if (desk3Button) desk3Button.addEventListener('click', handleDesk3Click);
      if (desk4Button) desk4Button.addEventListener('click', handleDesk4Click);
  
      updatePdcClass();
      window.addEventListener('resize', updatePdcClass);
  
      return () => {
        if (desk1Button) desk1Button.removeEventListener('click', handleDesk1Click);
        if (desk2Button) desk2Button.removeEventListener('click', handleDesk2Click);
        if (desk3Button) desk3Button.removeEventListener('click', handleDesk3Click);
        if (desk4Button) desk4Button.removeEventListener('click', handleDesk4Click);
  
        window.removeEventListener('resize', updatePdcClass);
      };
    }, []);
  
    return (
      <>
        <Header />
        <div className="mens-benner" style={{ backgroundImage: 'url(https://13-century.com/files/newarrival.webp)', backgroundPosition: 'center 60%' }}>
          <div className="videoBoxInfo text-light" style={{ paddingBottom: '50px' }}>
            <h3>Mens</h3>
          </div>
        </div>
  
        <section className="collection">
          <div className="d-flex justify-content-between align-items-center border-bottom">
            <div className="btn-group" role="group" aria-label="Filter and Sort">
              <button className="btn btn-light bt-chek" data-action="open-drawer" data-drawer-id="collection-filter-drawer" aria-label="Show filters">
                <i className="bi bi-funnel-fill"></i> Filter
              </button>
              <button className="btn btn-light bt-chek dropdown-toggle" aria-label="Show sort by" aria-haspopup="true" aria-expanded="false" aria-controls="collection-sort-popover">
                <i className="bi bi-sort-alpha-down"></i> Sort
              </button>
            </div>
            <div className="btn-group d-none d-lg-block bt-chek" role="group" aria-label="Layout switcher desktop">
              <button className="btn btn-light" id="desk1" aria-label="Show two products per row">
                <i className="bi bi-grid-3x3-gap-fill"></i>
              </button>
              <button className="btn btn-light bt-chek" id="desk2" aria-label="Show four products per row">
                <i className="bi bi-grid-fill"></i>
              </button>
            </div>
            <div className="btn-group d-lg-none" role="group" aria-label="Layout switcher mobile">
              <button className="btn btn-light bt-chek" id="desk4" aria-label="Show one product per row">
                <i className="bi bi-square-fill"></i>
              </button>
              <button className="btn btn-light bt-chek" id="desk3" aria-label="Show two products per row">
                <i className="bi bi-grid-fill"></i>
              </button>
            </div>
          </div>
        </section>
  
        <section className="collection-grid">
  <div className="row row-cols-1 p-1 g-2">
    {Object.keys(models).map((model) => {
      const gender = genderId[model]; // Get the gender for the current model
      return (
        <div id="pdc" className="col-4" key={model}>
          <div className="card">
            <Link id='tpimg' className='tpimg' to={`/${gender}/${model}`} style={{ textDecoration: 'none' }}>
              <img
                src={hoveredImage[model] || `https://13-century.com/files/${gender}/${model}/${models[model][0]}.webp`}
                className="card-img-top pd"
                alt={model}
              />
            </Link>
            <div className="card-body">
              <h5 className="card-title justify-content-center d-flex">{model}</h5>
              <Flickity className={'carousel carousel-nav'} options={{ pageDots: false }}>
                {models[model].map((color) => (
                  <div
                    className="carousel-cell productlist"
                    key={color}
                    onMouseEnter={() => setHoveredImage((prev) => ({ ...prev, [model]: `https://13-century.com/files/${gender}/${model}/${color}.webp` }))}
                    onMouseLeave={() => setHoveredImage((prev) => ({ ...prev, [model]: `https://13-century.com/files/${gender}/${model}/${models[model][0]}.webp` }))}
                  >
                    <img src={`https://13-century.com/files/${gender}/${model}/${color}.webp`} alt={model} />
                  </div>
                ))}
              </Flickity>
            </div>
          </div>
        </div>
      );
    })}
  </div>
</section>

        <Footer/>
      </>
    );
};

export default Womenssun;
