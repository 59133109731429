import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import only the necessary Bootstrap CSS
import Header from '../common/head';

import Footer from '../common/footer';
import Flickity from 'react-flickity-component';
import 'flickity/dist/flickity.min.css';
import '../home.css';
// Lazy loading for Bootstrap JavaScript
import 'bootstrap/dist/js/bootstrap.esm.min.js';
import { Link } from 'react-router-dom';

const HomePage = ({ products }) => {
  const [hoveredImage, setHoveredImage] = useState({}); // State to track hovered image

  const models = {
    '1030': ['C1', 'C2', 'C3', 'C4', 'C5'],
    '1031': ['C1', 'C2', 'C3', 'C4', 'C5'],
    '1033': ['C1', 'C2', 'C3', 'C4', 'C5'],
    '5024': ['C1', 'C2', 'C4', 'C5'],
    '2155': ['C2', 'C4', 'C5'],
    '2370': ['C1', 'C2', 'C3', 'C5', 'C6'],
    '6324': ['C37', 'C93'],
    '6334': ['C1', 'C3', 'C4'],
    '6608': ['C1', 'C3', 'C4', 'C6'],
    '8070': ['C2', 'C3'],
    '9833': ['C1', 'C4', 'C5', 'C6'],
    '9834': ['C1', 'C2', 'C5', 'C6'],
    '29035': ['C3', 'C4', 'C6'],
    '2002':['C1','C5'],

    // Add more models with their respective color codes
  };

  const genderId = {
    '2170': 'womens',
    '2002': 'womens',
    '6337': 'womens',
    '6351': 'womens',
    '7005': 'womens',
    '7436': 'womens',
    '7479': 'womens',
    '8816': 'womens',
    '1013':'women',
    '1027':'women',
    '1029':'women',
    '1032':'women',
    '2205':'women',
    '2256':'women',
    '2501':'women',
    '3011':'women',
    '3127':'women',
    '5848':'women',
    '6013':'women',
    '6016':'women',
    '8273':'women',
    '8868':'women',
    '0759': 'mens',
    '29035': 'mens',
    '6608': 'mens',
    '6324': 'mens',
    '2002M': 'mens',
    '8070': 'mens',
    '6334': 'mens',
    '2155': 'mens',
    '9834': 'mens',
    '9833': 'mens',
    '2370': 'mens',
    '1014':'men',
    '1024':'men',
    '1025':'men',
    '1028':'men',
    '1030':'men',
    '1031':'men',
    '1033':'men',
    '5024':'men',
    '6022':'men',
    '7106':'men',
    '8617':'men',
    '8620':'men',
    '10004':'men',
    '10006':'men',
    '10007':'men',
    '10010':'men'
  };

  // eslint-disable-next-line no-unused-vars
  const [gdId, setGdid] = useState(genderId);

  return (
    <div>
      <Header />

      {/* Preload Key Resources */}
      

      <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="videoBox">
              {/* Lazy load video with fallback image */}
              <div className="fullscreen-video-wrap">
                <img
                loading="lazy"
                src='https://13-century.com/files/home.webp'
                
                alt='bannerimg'
                />
                
                
              </div>
              <div className="overlay"></div>
              <div className="videoBoxInfo" style={{ paddingBottom: '50px', paddingTop: '420px' }}>
                <p className='text-light'>SPRING SUMMER 2024</p>
                <a href="/new arrivals" className="videoBoxInfoBtn btn btn-outline-light">
                  SHOP NOW
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="main text-dark">

        <Flickity className={'carousel product-list'} options={{ pageDots: false }}>
        {Object.keys(models).map((model) => {
      const gender = genderId[model]; // Get the gender for the current model
      return (
        <div className="carousel-cell products-list" key={model}>
              <div className="product-item" >
                {/* Lazy load images */}
                <Link to={`/${gender}/${model}`}>
                <img
                  loading="lazy"
                  src={hoveredImage[model] || `https://13-century.com/files/${gender}/${model}/${models[model][0]}.webp`}
                  className="card-img-top"
                  alt={model}
                />
                </Link>
                
                <Flickity
                  className={'carousel carousel-nav'}
                  options={{
                    asNavFor: '.carousel-main',
                    contain: true,
                    pageDots: false,
                    prevNextButtons: true,
                    draggable: true,
                  }}
                >
                  {models[model].map((color) => (
                    <div
                      className="carousel-cell productlist"
                      key={color}
                      onMouseEnter={() =>
                        setHoveredImage((prev) => ({
                          ...prev,
                          [model]: `https://13-century.com/files/${gender}/${model}/${color}.webp`,
                        }))
                      }
                      onMouseLeave={() =>
                        setHoveredImage((prev) => ({
                          ...prev,
                          [model]: `https://13-century.com/files/${gender}/${model}/${models[model][0]}.webp`,
                        }))
                      }
                    >
                      {/* Lazy load thumbnail images */}
                      <img loading="lazy" src={`https://13-century.com/files/${gender}/${model}/${color}.webp`} alt={model} />
                    </div>
                  ))}
                </Flickity>
                
                <p className="h6 justify-self-center align-content-center">{model}</p>
              </div>
            </div>
      );
    })}
        </Flickity>
      </section>

      <section>
        <div className="container-fluid ms-0 me-0 p-0">
          <div className="row ms-0 flex-lg-nowrap overflow-hidden text-light collection-gd">
            {[
              { img: 'A7_02553.webp', text: 'Eyeglasses For Women', link: '/eyewear/womens' },
              { img: 'A7_02626.webp', text: 'Eyeglasses For Men', link: '/eyewear/men' },
              { img: 'A7_02602.webp', text: 'Sunglasses For women', link: '/sunglasses/womens' },
              { img: 'A7_02607.webp', text: 'Sunglasses For men' , link: '/sunglasses/mens' },
            ].map((item, index) => (
              <div className="col-3 p-0 position-relative bg-black" key={index}>
                {/* Lazy load banner images */}
                <img
                  className="img-fluid banner-img"
                  style={{ width: '354.38px' }}
                  src={`https://13-century.com/files/banner_img/${item.img}`}
                  alt=""
                  loading="lazy"
                />
                <div
                  className="position-absolute"
                  style={{
                    bottom: '50px',
                    padding: '0px 50px',
                    fontWeight: 100,
                    fontSize: '1.5rem',
                    textTransform: 'uppercase',
                  }}
                >
                  <p>{item.text}</p>
                  <a className="btn btn-outline-light" style={{ width: '145px', height: '35px', fontWeight: 100 }} href={item.link}>
                    View Now
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section>
        
      </section>

      <Footer />
    </div>
  );
};

export default HomePage;
